<template>
    <BaseControl v-bind="$attrs" :errors="combinedErrors">
        <markdown-editor v-model="local"></markdown-editor>
    </BaseControl>
</template>

<script>
import MarkdownEditor from "@Platon/components/form/controls/MarkdownEditor.vue"
import BaseControl from "@Platon/components/form/controls/BaseControl.vue"
import InputControl2Mixin from "@Platon/mixins/InputControl2Mixin"
import ValidationMixin from "@Platon/mixins/ValidationMixin"

export default {
    name: "MarkdownEditorControl",
    components: { BaseControl, MarkdownEditor },

    mixins: [InputControl2Mixin, ValidationMixin],

    props: {
        value: {
            type: String
        }
    },

    data() {
        return {
            local: this.value
        }
    },

    watch: {
        local(newVal) {
            this.$emit("input", newVal)
        },

        value(newValue) {
            this.local = newValue
        }
    }
}
</script>
